.congratulationsPage {
  min-height: calc(100vh - 72px);
  width: 100%;
  .banner {
    border-radius: 8px;
    background-color: var(--secondary-light-color);
    position: relative;
    margin-bottom: 15px;
    margin-top: 15px;
    overflow: hidden;
    text-align: center;
    padding: 40px;
    .graphic {
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 0.3;
    }
    .icon {
      display: block;
      margin: auto;
      width: 60px;
      height: 60px;
      border-radius: 500px;
      background-color: #fff;
      padding: 15px;
    }
    h3 {
      font-size: 29px;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 20px;
      background: var(--top-loader-background);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
    p {
      font-size: 18px;
      color: var(--text-color-2);
      max-width: 700px;
      margin: auto;
    }
  }
  h6 {
    font-size: 24px;
    color: var(--text-color-1);
    text-align: center;
    margin-bottom: 50px;
    font-weight: 500;
  }

  button.button-primary {
    float: right;
  }
}
