.loanApplication {
  padding-top: 15px !important;
  .resumeApplication {
    display: flex;
    gap: 24px;
    > svg {
      margin-top: 10px;
    }
    h3 {
      margin-bottom: 5px;
      color: var(--text-color-1);
    }
    p {
      color: var(--text-color-6);
    }
  }
  button.button-primary {
    float: right;
  }
  .rangeSlider {
    margin-top: 16px;
  }
}
