//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

div[data-kt-stepper-element='content'] > div {
  width: 100%;
}
body > iframe[style*='2147483647'] {
  display: none;
}

input.range-slider + .range-slider__tooltip {
  display: none;
  transform: translateX(8px);
}

input.range-slider:hover + .range-slider__tooltip {
  display: block;
}

.stepper-bg {
  // background-repeat: no-repeat;
}

.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}

.svg-icon.mobile-icon-svg svg {
  height: 10rem !important;
  width: 10rem !important;
  max-height: none !important;
}

.custom-accordion {
  .accordion-header {
    .accordion-button:after {
    }
  }
}

.int-phone-input {
  padding-left: 10px;
  background-color: #eef3f7;

  .PhoneInputInput {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #181c32;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e4e6ef;
    appearance: none;

    min-height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.5rem;
    font-size: 1.15rem;
    border-radius: 0.475rem;

    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color 0.2s ease, background-color 0.2s ease;
    box-shadow: none !important;

    &:focus,
    &:active {
      outline: 0;
      background-color: #eef3f7;
      border-color: #eef3f7;
    }
  }
}

.loan-application {
  .selected {
    color: #ec0055 !important;
    border-color: #ec0055 !important;
    background-color: #f1faff !important;
  }
}

.accent-color {
  color: #ec0055 !important;
}

.plc-info-header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #190356;
}

.plc-info-content {
  font-weight: 300;
  line-height: 137.19%;
}

.plc-info-content li {
  margin: 5px 0 0 0;
}
