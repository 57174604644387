// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';

.full-page-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba(0, 0, 0, 0.4);
}

.custom-alert {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 400px;
  z-index: 9999999;
}

.inline-block {
  display: inline-block;
  padding: 0;
}

.upload {
  position: relative;
  border-radius: 6px;
  background-color: #ec0055;
  // margin-right: 20px;
  & input[type='file'] {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 45px;
    opacity: 0;
    cursor: pointer;
  }
  & label {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 16px;
    color: #fff;
    width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.form-check-input[type='checkbox'].custom-checkbox {
  border-radius: 50%;
}

.form-check-input.custom-checkbox:checked[type='checkbox'] {
  background-size: 60%;
}

.circular-progressbar-card {
  h5 {
    color: #464e5f;
  }
  .d-flex {
    .progress-container {
      width: 120px;
      height: 120px;
      .CircularProgressbar {
        .CircularProgressbar-path {
          transform-origin: center;
          transform: rotate(45deg);
          stroke: #ec0055;
        }
        .CircularProgressbar-trail {
          stroke: #ec00551a;
        }
        .CircularProgressbar-text {
          fill: #464e5f;
          font-weight: 500;
        }
      }
    }
  }
  .progress-bar-text {
    font-weight: 300;
    color: #80808f;
  }
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// .datepicker {
//   &.react-datetime-picker {
//     width: 100%;
//   }

//   .react-datetime-picker__calendar--open {
//     inset: 100% auto auto auto !important;
//   }

//   .react-datetime-picker__wrapper {
//     border: 0;
//     background: #f5f8fa;
//   }

//   .react-datetime-picker__inputGroup {
//     padding: 10px 20px;
//     color: #686578;
//     font-weight: bold;
//     font-size: 14px;
//     line-height: 1;
//     border-radius: 4px;
//     input {
//       color: #686578;
//     }
//   }
// }

.custom-accordion {
  .accordion-button {
    font-size: 14px;
  }
}

.loan-content {
  .head-title {
    background-color: #ec0055;
    color: #fff;
    margin-bottom: 20px;
  }

  .loan-features {
    ul {
      list-style: none;

      li {
      }
    }

    .icon i {
      font-size: 30px;
      color: #ec0055;
    }
  }

  .loan-box {
    border: 2px solid #ddd;
    margin: 20px auto;
    padding: 20px;

    i {
      font-size: 30px;
      color: #ec0055;
      margin-right: 10px;
    }
  }

  .imp-info {
    margin-bottom: 40px;
  }

  .info-accordion {
    .accordion-button {
      font-size: 16px;
      background: #525ee3;
      color: #fff;

      .plus {
        display: none;
      }
      .minus {
        display: block;
      }

      &.collapsed {
        .plus {
          display: block;
        }
        .minus {
          display: none;
        }
      }

      &:after {
        display: none;
      }
    }

    .accordion-item {
      border-color: #525ee3;
    }

    .accordion-header {
      i {
        color: #fff;
      }
    }
  }

  .faqs {
    .faq-title {
    }

    .faq-q {
      padding-left: 15px;
      margin-top: 10px;
      font-weight: bold;
      span {
        margin-left: -15px;
      }
    }

    .faq-a {
      padding-left: 15px;
      span {
        margin-left: -15px;
      }
    }
  }
}

.react-calendar-datetime-picker .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ec0055 !important;
  padding: 1.5rem 1rem !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  position: relative !important;
  left: auto !important;
}

.react-calendar-datetime-picker .input-picker .input-picker--input {
  text-align: left;
  background: #f5f8fa;
  border: 0;
  font-size: 14.95px;
  padding: 12px 8px;
  height: auto;
}

.react-calendar-datetime-picker .daysList .is-disabled {
  background-color: #efefef;
  color: #959595;
}

.react-calendar-datetime-picker .daysList .is-selected-day {
  background-color: #ec0055;
  color: white;
}

.react-calendar-datetime-picker .daysList .is-today {
  box-shadow: inset 0px 0px 0px 2px #ec0055;
}

//

.react-datepicker-wrapper,
.react-datepicker__input-container,
input {
  width: 100%;
}

.react-datetime-picker__calendar--open {
  inset: 100% auto auto auto !important;
}

.react-datepicker__input-container,
input {
  border: 0;
  &[disabled] {
    color: rgba(94, 98, 120, 0.4) !important;
  }
}
.PhoneInputCountry {
  select.PhoneInputCountrySelect[disabled] {
    opacity: 0;
    + .PhoneInputCountryIcon {
      opacity: 0.4;
      + .PhoneInputCountryIconImg {
        opacity: 0.4;
      }
    }
  }
}
.react-datepicker__month {
  padding: 10px;
  font-size: 14px;
  line-height: 1;
  color: #959595;
}

.react-datepicker__header {
  background-color: #ec0055 !important;
  padding: 1.5rem 1rem !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-size: 14px;
  color: white !important;
}

.react-datepicker__input-container,
input {
  text-align: left;
  border: 0;
  font-size: 14.95px;
  padding: 6px 8px;
  height: auto;
}

.react-datepicker__day-name,
.react-datepicker__current-month {
  color: white;
}
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background: white;
}
.react-datepicker__month-option,
.react-datepicker__year-option {
  color: black;
}

.react-datepicker__navigation--years-previous {
  color: #464e5f;
}

.react-datepicker__year-select,
.react-datepicker__month-select {
  background: transparent;
  border: none;
  color: white;
}

.react-datepicker__month-select option,
.react-datepicker__year-select option {
  color: black;
}

.react-datepicker__year-select:focus,
.react-datepicker__month-select:focus {
  outline: none !important;
  box-shadow: none !important;
}
